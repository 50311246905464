<template>
  <div class="card">
    <div
      class="card-title bg-info py-2 text-center text-uppercase"
      style="color: rgb(242, 234, 14)"
    >
      <h3 class="mb-0 fz-md font-weight-bold">
        {{ InfoPhoneTelegram.status == -1 ? 'CONNECT' : 'INFO' }} TELEGRAM
      </h3>
    </div>
    <div class="card-body">
      <b-row>
        <b-col md="10" cols="12" class="mx-auto">
          <b-form @submit.prevent="onUpdatePhoneTelegram">
            <div class="form-group">
              <p>Step 1: Enter your phone number</p>
              <p>Step 2: Select the telegram icon</p>
              <p>Step 3: Confirm your phone number with telegram bot</p>
              <p>Step 4: Select Save button to connect your telegram</p>
              <b-input-group class=""> </b-input-group>
            </div>
            <div class="form-group">
              <b-row>
                <b-col md="3" cols="12">
                  <label for="User_New_Password ">Country</label>
                  <vue-country-code class="form-control" enabledCountryCode="true">
                  </vue-country-code>
                </b-col>
                <b-col md="9" cols="12">
                  <label for="User_New_Password ">Phone</label>
                  <b-input-group class="">
                    <template #prepend>
                      <b-input-group-text class="p-0 border-0 phone-icon">
                        <b-button variant="info">
                          <i class="fad fa-phone"></i>
                        </b-button>
                      </b-input-group-text>
                    </template>
                    <b-form-input
                      v-if="InfoPhoneTelegram.status == -1"
                      required
                      type="text"
                      id="User_New_Password "
                      v-model.trim="update.phone_telegram"
                      placeholder="Enter Your Phone Number Here"
                    ></b-form-input>
                    <b-form-input
                      v-if="InfoPhoneTelegram.status == 1"
                      readonly
                      type="text"
                      id="User_New_Password "
                      v-model.trim="InfoPhoneTelegram.phone_telegram"
                      placeholder="Enter Your Phone Number Here"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
            </div>
            <div
              class="form-group d-flex justify-content-center"
              v-if="InfoPhoneTelegram.status == -1"
            >
              <b-button class="mx-auto min-width-160 mt-3" variant="animation-info " type="submit"
                >Connect</b-button
              >
            </div>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      update: {
        phone_telegram: '',
      },
      show1: false,
      show2: false,
      show3: false,
    };
  },
  computed: {
    ...mapGetters({
      InfoPhoneTelegram: 'user/InfoPhoneTelegram',
    }),
  },
  methods: {
    onUpdatePhoneTelegram() {
      this.$store.dispatch('user/req_postConnectPhoneTelegram', this.update);
    },
  },
  created() {},
};
</script>

<style lang="scss">
.vue-country-select .dropdown {
  width: 100%;
  align-items: flex-start;
}
.vue-country-select .current {
  width: 100%;
}
.country-code,
.dropdown-arrow {
  width: 50%;
}
.dropdown-arrow {
  text-align: right;
}
.box-change {
  width: 100%;
  min-height: 50px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 7px 10px 2px #00000038;
  position: relative;
  margin-bottom: 1.25rem;
  .box-title {
    background-color: #009750;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    padding: 0 1.25rem;
    .text {
      color: #ffffff;
      font-weight: 600;
      font-size: 20px;
      text-transform: capitalize;
    }
    .icon {
      cursor: pointer;
      img {
        height: 20px;
        width: auto;
      }
      &.active {
        transform: rotate(180deg);
      }
    }
  }
  .content-box {
    padding: 1.25rem;
    .warning-changepass {
      font-weight: 700;
      color: darkorange;
      font-size: clamp(15px, 2.5vw, 16.2px);
      margin-bottom: 15px;
      text-transform: capitalize;
      text-align: center;
    }
    .form-group {
      label {
        font-weight: 600;
        color: #6c757d;
        font-size: 16px;
        margin-bottom: 5px;
      }
      .input-group-text {
        background: transparent;
        padding: 0;
        border: 1px solid #e9ecef;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        .btn {
          position: relative;
          z-index: 0;
        }
      }
      input {
        border: 1px solid #e9ecef;
        height: 40px;
        font-size: clamp(15px, 1vw, 16.2px);
        font-weight: 600;
        &:focus {
          box-shadow: 0 0 0 1px rgba(56, 169, 73, 45%);
        }
        &::placeholder {
          font-weight: 500;
          font-size: clamp(13px, 1vw, 15px);
        }
      }
    }
  }
}
.phone-icon {
  position: relative;
  z-index: 0;
}
</style>
