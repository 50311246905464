<template>
  <div class="card">
    <div
      class="card-title bg-info py-2 text-center text-uppercase"
      style="color: rgb(242, 234, 14)"
    >
      <h3 class="mb-0 fz-md font-weight-bold">Address</h3>
    </div>
    <div class="card-body">
      <b-form @submit.prevent="onUpdateAddress">
        <div class="form-group d-flex justify-content-center">
          <b-button
            v-if="!UserInfo.address"
            class="mx-auto min-width-160 mt-3 text-uppercase"
            variant="animation-info"
            type="submit"
            >Click to update address - USDT BEP-20 ( Metamask )</b-button
          >
          <b-button
            v-else
            class="mx-auto min-width-160 mt-3 text-uppercase"
            variant="animation-info"
            @click="showFormMetamask = !showFormMetamask"
            >Info address - USDT BEP-20 ( Metamask )</b-button
          >
        </div>
        <template v-if="showFormMetamask">
          <div class="form-group">
            <label for="User_Password">Address USDT BEP-20 ( Metamask )</label>
            <b-input-group class="">
              <template #prepend>
                <b-input-group-text class="p-0 border-0">
                  <b-button variant="info">
                    <i class="fad fa-key"></i>
                  </b-button>
                </b-input-group-text>
              </template>
              <b-form-input
                type="text"
                readonly
                v-model.trim="UserInfo.address"
                placeholder="Enter Your Address USDT BEP-20 ( Metamask )"
              ></b-form-input>
            </b-input-group>
          </div>
        </template>
      </b-form>
      <b-form @submit.prevent="onUpdateTronLink">
        <div class="form-group d-flex justify-content-center">
          <b-button
            class="mx-auto min-width-160 mt-3 text-uppercase"
            variant="animation-info "
            @click="showFormTronlink = !showFormTronlink"
            >{{ UserInfo.addressTron ? 'Info' : 'Click to update' }} address USDT TRC-20 ( Tronlink
            )</b-button
          >
        </div>
        <template v-if="showFormTronlink">
          <div class="form-group">
            <label for="User_Password">Address USDT TRC-20 ( Tronlink )</label>
            <b-input-group class="">
              <template #prepend>
                <b-input-group-text class="p-0 border-0">
                  <b-button variant="info">
                    <i class="fad fa-key"></i>
                  </b-button>
                </b-input-group-text>
              </template>
              <b-form-input
                v-if="!UserInfo.addressTron"
                type="text"
                required
                v-model.trim="addressTronlink"
                placeholder="Enter Your Address USDT TRC-20 ( Tronlink )"
              ></b-form-input>
              <b-form-input
                v-else
                type="text"
                readonly
                v-model.trim="UserInfo.addressTron"
                placeholder="Enter Your Address USDT TRC-20 ( Tronlink )"
              ></b-form-input>
            </b-input-group>
          </div>
          <div class="form-group d-flex justify-content-center">
            <b-button
              v-if="!UserInfo.addressTron"
              class="mx-auto min-width-160 mt-3 text-uppercase"
              variant="animation-info"
              type="submit"
              >Update</b-button
            >
          </div>
        </template>
      </b-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Web3 from 'web3';

const WAValidator = require('multicoin-address-validator');

const axios = require('@/store/config/axios.config').default;

export default {
  data() {
    return {
      update: {
        address: '',
      },
      showFormTronlink: false,
      showFormMetamask: false,
      addressTronlink: '',
    };
  },
  computed: {
    ...mapGetters({
      UserInfo: 'user/UserInfo',
    }),
  },
  methods: {
    ...mapMutations(['onLoad', 'outLoad']),
    async onUpdateAddress() {
      try {
        const checkWallet = await this.checkWallet();
        if (!checkWallet) {
          return;
        }
        const wallet = Web3.utils.toChecksumAddress(await this.$store.state.wallet.addr);
        const data = await axios.post('api/v1/profile/update-address-wallet', {
          address: wallet,
        });
        if (!data) {
          this.$store.commit('auth/REGISTER_SUCCESS');
          return;
        }
        if (data && data.message) {
          const signature = data.message;
          const txData = await window.ethereum.request({
            method: 'personal_sign',
            params: [wallet, signature],
          });
          // eslint-disable-next-line no-shadow
          const data2 = await axios.post('api/v1/profile/update-address-wallet', {
            address: wallet,
            signature: txData,
            message: data.message,
          });
          if (data2) {
            this.$store.dispatch('user/req_getInfo');
          }
        } else {
          this.$toastr.e('Data is null', 'Oops!');
        }
      } catch (e) {
        this.$toastr.e(e.message, 'Oops!');
        this.outLoad();
      }
    },
    onUpdateTronLink() {
      const valid = WAValidator.validate(this.addressTronlink, 'trx');

      if (valid) {
        // console.log('This is a valid address');
      } else {
        this.$toastr.e('Your Address is not valid.', 'Withdraw Failed');
        return;
      }
      this.$store.dispatch('user/req_postUpdateAddressTronLink', { address: this.addressTronlink });
    },
  },
};
</script>

<style lang="scss">
.box-change {
  width: 100%;
  min-height: 50px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 7px 10px 2px #00000038;
  position: relative;
  margin-bottom: 1.25rem;
  .box-title {
    background-color: #009750;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    padding: 0 1.25rem;
    .text {
      color: #ffffff;
      font-weight: 600;
      font-size: 20px;
      text-transform: capitalize;
    }
    .icon {
      cursor: pointer;
      img {
        height: 20px;
        width: auto;
      }
      &.active {
        transform: rotate(180deg);
      }
    }
  }
  .content-box {
    padding: 1.25rem;
    .warning-changepass {
      font-weight: 700;
      color: darkorange;
      font-size: clamp(15px, 2.5vw, 16.2px);
      margin-bottom: 15px;
      text-transform: capitalize;
      text-align: center;
    }
    .form-group {
      label {
        font-weight: 600;
        color: #6c757d;
        font-size: 16px;
        margin-bottom: 5px;
      }
      .input-group-text {
        background: transparent;
        padding: 0;
        border: 1px solid #e9ecef;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      input {
        border: 1px solid #e9ecef;
        height: 40px;
        font-size: clamp(15px, 1vw, 16.2px);
        font-weight: 600;
        &:focus {
          box-shadow: 0 0 0 1px rgba(56, 169, 73, 45%);
        }
        &::placeholder {
          font-weight: 500;
          font-size: clamp(13px, 1vw, 15px);
        }
      }
    }
  }
}
</style>
