<template>
  <b-container fluid class="user-page p-0">
    <b-row>
      <b-col cols="12" xl="4" lg="5">
        <div class="box-user" ref="boxUser">
          <!-- <div class="verify-status" :class="{ verify: UserKYC.status == 1 && UserInfo.name }" :key="UserKYC.status">
            <div class="icon-status" v-if="UserKYC.status >= 1 && UserInfo.name">
              <img src="~@/assets/images/logo/light.png" alt="" width="25" />
            </div>
            <div class="icon-status" v-else>
              <i class="fad fa-times-hexagon"></i>
            </div>
            <div class="text-status">{{ UserKYC.status == 1 && UserInfo.name? 'Verified' : 'Unverifying' }}</div>
          </div> -->
          <div class="user pb-0">
            <div class="avatar mx-auto">
              <img src="~@/assets/images/logo/light.png" alt="" />
            </div>
            <div class="info">
              <div class="email">
                ID: <span>{{ UserInfo.id ? UserInfo.id : 'Not Update' }}</span>
              </div>
              <div class="email">
                Email:
                <span>{{
                  UserInfo.email
                    ? add3Dots(UserInfo.email, 6) +
                      UserInfo.email.slice(UserInfo.email.length - 10, UserInfo.email.length)
                    : 'Not Update'
                }}</span>
              </div>
              <div class="email">
                Address USDT-BEP20:
                <span>
                  {{
                    UserInfo.address
                      ? add3Dots(UserInfo.address, 8) +
                        UserInfo.address.slice(UserInfo.address.length - 4, UserInfo.address.length)
                      : 'Not Update'
                  }}
                </span>
              </div>
              <div class="email">
                Address USDT-TRC20:
                <span>
                  {{
                    UserInfo.addressTron
                      ? add3Dots(UserInfo.addressTron, 8) +
                        UserInfo.addressTron.slice(
                          UserInfo.addressTron.length - 4,
                          UserInfo.addressTron.length,
                        )
                      : 'Not Update'
                  }}
                </span>
              </div>
            </div>
          </div>
          <div class="user pb-0">
            <div class="info">
              <div class="name mb-2">
                <span class="btn btn-outline-green d-block" @click="showComponent('ConnectBinance')"
                  >CONNECT API KEY BINANCE</span
                >
              </div>
              <div class="name mb-2">
                <a
                  class="btn btn-outline-green d-block"
                  href="http://t.me/notice_profit_trade_aiha_bot"
                  target="_blank"
                  >CONNECT TELEGRAM</a
                >
              </div>
              <div class="name">
                <span
                  class="btn d-block"
                  :class="UserAuth.enable ? 'btn-outline-danger' : 'btn-outline-green'"
                  @click="showComponent('AuthBox')"
                  >{{ UserAuth.enable ? 'Disable' : 'Enable' }} AUTHENTICATION</span
                >
              </div>
            </div>
          </div>
          <div class="more-action">
            <div class="nav-item-profile">
              <a
                :class="{ active: boxActive == 'UpdateEmail' }"
                @click="showComponent('UpdateEmail')"
              >
                <i class="fas fa-envelope"></i>
                <span> {{ !UserInfo.email ? ' UPDATE EMAIL' : 'INFO EMAIL' }} </span>
              </a>
            </div>
            <div class="nav-item-profile">
              <a
                :class="{ active: boxActive == 'UpdateAddress' }"
                @click="showComponent('UpdateAddress')"
              >
                <i class="fas fa-envelope"></i>
                <span> {{ !UserInfo.address ? ' CONNECT ADDRESS' : 'INFO ADDRESS' }} </span>
              </a>
            </div>
            <div class="nav-item-profile">
              <a
                :class="{ active: boxActive == 'ConnectBinance' }"
                @click="showComponent('ConnectBinance')"
              >
                <i class="fas fa-key"></i>
                <span
                  >{{
                    InfoBinance.status == -1
                      ? 'CONNECT BINANCE API KEY'
                      : 'INFO BINANCE API KEY CONNECT'
                  }}
                </span>
              </a>
            </div>
            <div class="nav-item-profile">
              <a
                href="http://t.me/notice_profit_trade_aiha_bot"
                target="_blank"
                :class="{ active: boxActive == 'ConnectTelegram' }"
              >
                <i class="fas fa-paper-plane"></i>
                <span>{{
                  InfoPhoneTelegram.status == -1 ? 'CONNECT TELEGRAM' : 'INFO TELEGRAM CONNECT'
                }}</span>
              </a>
            </div>
            <div class="nav-item-profile">
              <a :class="{ active: boxActive == 'AuthBox' }" @click="showComponent('AuthBox')">
                <i class="far fa-user-shield"></i>
                <span> TWO - FACTOR AUTHENTICATION </span>
              </a>
            </div>
            <div class="nav-item-profile" v-if="UserInfo.email">
              <a :class="{ active: boxActive == 'ChangeBox' }" @click="showComponent('ChangeBox')">
                <i class="fad fa-dice-d4"></i>
                <span> CHANGE PASSWORD </span>
              </a>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" xl="8" lg="7" class="content-profile pr-lg-0">
        <component :is="boxActive" :key="`box-profile-${boxActive}`" :ref="boxActive"></component>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';

import VerifyBox from '@/components/profile/VerifyBox.vue';
import AuthBox from '@/components/profile/AuthBox.vue';
import ChangeBox from '@/components/profile/ChangeBox.vue';
import ConnectTelegram from '@/components/profile/ConnectTelegram.vue';
import ConnectBinance from '@/components/profile/ConnectBinance.vue';
import UpdateAddress from '@/components/profile/UpdateAddress.vue';
import UpdateEmail from '@/components/profile/UpdateEmail.vue';

export default {
  components: {
    VerifyBox,
    AuthBox,
    ChangeBox,
    ConnectTelegram,
    ConnectBinance,
    UpdateAddress,
    UpdateEmail,
  },
  data() {
    return {
      kyc_status: false,
      boxActive: 'UpdateAddress',
      email: 'violet200945@gmail.com',
      widthp: 100,
    };
  },
  computed: {
    ...mapGetters({
      InfoPhoneTelegram: 'user/InfoPhoneTelegram',
      InfoBinance: 'user/InfoBinance',
      UserInfo: 'user/UserInfo',
      UserKYC: 'user/UserKYC',
      UserAuth: 'user/UserAuth',
    }),
  },
  watch: {},
  methods: {
    add3Dots(text, limit) {
      const dots = '...';
      if (text.length > limit) {
        // eslint-disable-next-line no-param-reassign
        text = `${text.substring(0, limit)} ${dots} `;
      }

      return text;
    },
    scrollToElement(elm) {
      const el = this.$refs[elm];

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
    showComponent(box) {
      if (box === 'ConnectTelegram') {
        this.$toastr.w('Coming soon', 'Notification !');
        return;
      }
      this.boxActive = box;
      this.scrollToElement(box);
    },
  },
  created() {
    this.$store.dispatch('user/req_getKYC');
    this.$store.dispatch('user/req_getInfoPhoneTelegram');
    this.$store.dispatch('user/req_getInfoBinance');
    this.$store.dispatch('user/req_getAuth');
  },
  mounted() {},
};
</script>

<style lang="scss">
.btn-outline-danger:hover,
.btn-outline-green:hover {
  color: #fff;
}
.user-page {
  .content-profile {
    @media (max-width: 991px) {
      margin-top: 40px;
    }
  }
  .box-user {
    background-color: rgba(0, 0, 20, 0.5);
    border: 1px solid rgb(255 255 255);
    border-radius: 15px;
    color: #fff;
    margin-bottom: 20px;
    position: relative;
    .verify-status {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #b91c30;
      width: 155px;
      position: absolute;
      right: 0;
      top: 1.25rem;
      border-top-left-radius: calc(40px * 1.25);
      border-bottom-left-radius: calc(40px * 1.25);
      padding: 0 15px 0 5px;

      &.verify {
        background: #009750;
        .text-status {
          font-size: clamp(17px, 2.5vw, 20px);
        }
        .icon-status {
          background: rgba(255, 255, 255, 0.527);
        }
      }
      .icon-status {
        width: 30px;
        height: 30px;
        // background: rgba(255, 255, 255, 0.527);
        border: 1px solid rgba(255, 255, 255, 0.8);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        i,
        svg {
          width: 100%;
          height: 100%;
          color: #ccc;
        }
      }
      .text-status {
        color: #fff;
        font-weight: 600;
        font-size: 18px;
      }
    }
    .user {
      padding: 1.25rem;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      @media (max-width: 1440px) {
        padding: 10px;
      }
      .avatar {
        padding: 0.25rem;
        max-width: 100%;
        height: auto;
        width: 200px;
        margin-bottom: 1.25rem;
        img {
          width: 100%;
          height: 100%;
        }
        @media (max-width: 1440px) {
          width: 65px;
          height: 65px;
        }
        @media (max-width: 767px) {
          width: 45px;
          height: 45px;
        }
      }
      .info {
        border-bottom: 4px solid rgba(255, 255, 255, 0.1);
        padding-bottom: 1.25rem;
        width: 100%;
        .name {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: #fff;
          margin-left: 5px;
          font-weight: 600;
          font-size: clamp(16px, 2vw, 17px);
          text-transform: uppercase;
          span {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: #ffff00 !important;
            font-weight: 600;
            font-size: clamp(15px, 2vw, 18px);
            margin-left: 10px;
          }
          .btn-outline-green {
            border: 1px #00af00 solid !important;
            color: #00af00 !important;
            width: 100%;
            margin: 0;
          }
          .btn-outline-green:hover {
            background: #00af00 !important;
          }
          .btn-outline-danger {
            border: 1px #ec1c24 solid !important;
            color: #ec1c24 !important;
            width: 100%;
            margin: 0;
          }
          .btn:hover {
            color: #fff !important;
          }
        }
        .email {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: #fff;
          margin-left: 5px;
          font-weight: 600;
          font-size: clamp(14px, 2vw, 15px);
          text-transform: uppercase;
          flex-wrap: wrap;
          span {
            color: #ffff00 !important;
            font-weight: 600;
            font-size: clamp(15px, 2vw, 18px);
            margin-left: 10px;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            max-width: calc(100% - 50px);
          }
        }
      }
    }
    .more-action {
      padding: 1.25rem 0;
      .nav-item-profile {
        margin: 10px 0;
        a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border-left: 3px solid transparent;
          -webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
          text-decoration: none;
          padding: 7px 20px;
          cursor: pointer;
          i,
          svg {
            width: 30px;
            display: inline-block;
            font-size: 20px;
            opacity: 0.8;
            vertical-align: middle;
            color: #fff;
            margin-right: 5px;
          }
          span {
            display: block;
            color: #fff;
          }
          &.active {
            span {
              color: #fff;
            }
            font-weight: 600;
            border-left-color: #008dff;
            background: linear-gradient(89deg, #fff3, #fff3);
          }
        }
      }
    }
  }
}
</style>
