<template>
  <div class="card">
    <div
      class="card-title bg-info py-2 text-center text-uppercase"
      style="color: rgb(242, 234, 14)"
    >
      <h3 class="mb-0 fz-md font-weight-bold">
        {{ InfoBinance.status == -1 ? 'CONNECT' : 'INFO' }} BINANCE API KEY
      </h3>
    </div>
    <div class="card-body">
      <!-- Connect firt -->
      <b-form @submit.prevent="onConnectBinance" v-if="InfoBinance.status == -1">
        <div class="form-group">
          <label for="User_Password">Binance API Key</label>
          <b-input-group class="">
            <template #prepend>
              <b-input-group-text class="p-0 border-0">
                <b-button variant="info">
                  <i class="fad fa-key"></i>
                </b-button>
              </b-input-group-text>
            </template>
            <b-form-input
              type="text"
              required
              v-model.trim="update.binance_api_key"
              placeholder="Enter Your Binance API Key"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="form-group">
          <label for="User_New_Password ">Secret Key</label>
          <b-input-group class="">
            <template #prepend>
              <b-input-group-text class="p-0 border-0">
                <b-button variant="info">
                  <i class="fad fa-key"></i>
                </b-button>
              </b-input-group-text>
            </template>
            <b-form-input
              required
              type="text"
              id="User_New_Password "
              v-model.trim="update.binance_api_secretkey"
              placeholder="Enter Your Secret Key"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="form-group d-flex justify-content-center">
          <b-button class="mx-auto min-width-160 mt-3" variant="animation-info " type="submit"
            >Connect</b-button
          >
        </div>
      </b-form>
      <!-- Connect continiue -->
      <b-form @submit.prevent="onUpdateBinance" v-if="InfoBinance.status == 1">
        <div class="form-group">
          <label for="User_Password">Binance API Key</label>
          <b-input-group class="">
            <template #prepend>
              <b-input-group-text class="p-0 border-0">
                <b-button variant="info">
                  <i class="fad fa-key"></i>
                </b-button>
              </b-input-group-text>
            </template>
            <b-form-input
              type="text"
              required
              v-model.trim="InfoBinance.binance_api_key"
              placeholder="Enter Your Binance API Key"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="form-group">
          <label for="User_New_Password ">Secret Key</label>
          <b-input-group class="">
            <template #prepend>
              <b-input-group-text class="p-0 border-0">
                <b-button variant="info">
                  <i class="fad fa-key"></i>
                </b-button>
              </b-input-group-text>
            </template>
            <b-form-input
              type="text"
              id="User_New_Password "
              v-model.trim="InfoBinance.binance_api_secretkey"
              placeholder="Enter Your Secret Key"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="form-group d-flex justify-content-center">
          <b-button class="mx-auto min-width-160 mt-3" variant="animation-info " type="submit"
            >Update</b-button
          >
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      update: {
        binance_api_key: '',
        binance_api_secretkey: '',
      },
      show1: false,
      show2: false,
      show3: false,
    };
  },
  computed: {
    ...mapGetters({
      InfoBinance: 'user/InfoBinance',
    }),
  },
  methods: {
    onConnectBinance() {
      this.$store.dispatch('user/req_postConnectBinance', this.update);
    },
    onUpdateBinance() {
      this.update = {
        binance_api_key: this.InfoBinance.binance_api_key,
        binance_api_secretkey: this.InfoBinance.binance_api_secretkey,
      };
      this.$store.dispatch('user/req_postConnectBinance', this.update);
    },
  },
  created() {},
};
</script>

<style lang="scss">
.box-change {
  width: 100%;
  min-height: 50px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 7px 10px 2px #00000038;
  position: relative;
  margin-bottom: 1.25rem;
  .box-title {
    background-color: #009750;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    padding: 0 1.25rem;
    .text {
      color: #ffffff;
      font-weight: 600;
      font-size: 20px;
      text-transform: capitalize;
    }
    .icon {
      cursor: pointer;
      img {
        height: 20px;
        width: auto;
      }
      &.active {
        transform: rotate(180deg);
      }
    }
  }
  .content-box {
    padding: 1.25rem;
    .warning-changepass {
      font-weight: 700;
      color: darkorange;
      font-size: clamp(15px, 2.5vw, 16.2px);
      margin-bottom: 15px;
      text-transform: capitalize;
      text-align: center;
    }
    .form-group {
      label {
        font-weight: 600;
        color: #6c757d;
        font-size: 16px;
        margin-bottom: 5px;
      }
      .input-group-text {
        background: transparent;
        padding: 0;
        border: 1px solid #e9ecef;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      input {
        border: 1px solid #e9ecef;
        height: 40px;
        font-size: clamp(15px, 1vw, 16.2px);
        font-weight: 600;
        &:focus {
          box-shadow: 0 0 0 1px rgba(56, 169, 73, 45%);
        }
        &::placeholder {
          font-weight: 500;
          font-size: clamp(13px, 1vw, 15px);
        }
      }
    }
  }
}
</style>
