<template>
  <div class="card">
    <div
      class="card-title bg-info py-2 text-center text-uppercase"
      style="color: rgb(242, 234, 14)"
    >
      <h3 class="mb-0 fz-md font-weight-bold">Change Password</h3>
    </div>
    <div class="card-body">
      <div class="warning-text text-warning text-center">
        You will have to log in again after your password is successfully changed
      </div>
      <b-form @submit.prevent="onUpdatePassword">
        <div class="form-group">
          <label for="User_Password">Current Password</label>
          <b-input-group class="">
            <template #prepend>
              <b-input-group-text class="p-0 border-0">
                <b-button
                  variant="info"
                  @click="show1 = !show1"
                  v-b-tooltip.hover
                  title="Show Password"
                >
                  <i class="fad fa-key"></i>
                </b-button>
              </b-input-group-text>
            </template>
            <b-form-input
              :type="show1 ? 'text' : 'password'"
              required
              id="User_Password"
              v-model.trim="change.user_password"
              placeholder="Enter Your Current Password"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="form-group">
          <label for="User_New_Password ">New Password</label>
          <b-input-group class="">
            <template #prepend>
              <b-input-group-text class="p-0 border-0">
                <b-button
                  variant="info"
                  @click="show2 = !show2"
                  v-b-tooltip.hover
                  title="Show Password"
                >
                  <i class="fad fa-key"></i>
                </b-button>
              </b-input-group-text>
            </template>
            <b-form-input
              :type="show2 ? 'text' : 'password'"
              required
              id="User_New_Password "
              v-model.trim="change.user_new_password"
              placeholder="Enter Your New Password"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="form-group">
          <label for="User_Re_New_Password ">Retype New Password</label>
          <b-input-group class="">
            <template #prepend>
              <b-input-group-text class="p-0 border-0">
                <b-button
                  variant="info"
                  @click="show3 = !show3"
                  v-b-tooltip.hover
                  title="Show Password"
                >
                  <i class="fad fa-key"></i>
                </b-button>
              </b-input-group-text>
            </template>
            <b-form-input
              :type="show3 ? 'text' : 'password'"
              required
              id="User_Re_New_Password "
              v-model.trim="change.user_re_new_password"
              placeholder="Enter Your New Password"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="form-group d-flex justify-content-center">
          <b-button class="mx-auto min-width-160 mt-3" variant="animation-info " type="submit"
            >Save</b-button
          >
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      change: {
        user_password: '',
        user_new_password: '',
        user_re_new_password: '',
      },
      show1: false,
      show2: false,
      show3: false,
    };
  },
  methods: {
    onUpdatePassword() {
      if (this.change.user_password === this.change.user_re_new_password) {
        this.$toastr.e('The new password needs to be different from the old password');
        return;
      }
      if (this.change.user_re_new_password !== this.change.user_new_password) {
        this.$toastr.e('Re-enter the password must match the new password');
        return;
      }
      this.$store.dispatch('user/req_postChangePassword', this.change);
    },
  },
};
</script>

<style lang="scss">
.box-change {
  width: 100%;
  min-height: 50px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 7px 10px 2px #00000038;
  position: relative;
  margin-bottom: 1.25rem;
  .box-title {
    background-color: #009750;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    padding: 0 1.25rem;
    .text {
      color: #ffffff;
      font-weight: 600;
      font-size: 20px;
      text-transform: capitalize;
    }
    .icon {
      cursor: pointer;
      img {
        height: 20px;
        width: auto;
      }
      &.active {
        transform: rotate(180deg);
      }
    }
  }
  .content-box {
    padding: 1.25rem;
    .warning-changepass {
      font-weight: 700;
      color: darkorange;
      font-size: clamp(15px, 2.5vw, 16.2px);
      margin-bottom: 15px;
      text-transform: capitalize;
      text-align: center;
    }
    .form-group {
      label {
        font-weight: 600;
        color: #6c757d;
        font-size: 16px;
        margin-bottom: 5px;
      }
      .input-group-text {
        background: transparent;
        padding: 0;
        border: 1px solid #e9ecef;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      input {
        border: 1px solid #e9ecef;
        height: 40px;
        font-size: clamp(15px, 1vw, 16.2px);
        font-weight: 600;
        &:focus {
          box-shadow: 0 0 0 1px rgba(56, 169, 73, 45%);
        }
        &::placeholder {
          font-weight: 500;
          font-size: clamp(13px, 1vw, 15px);
        }
      }
    }
  }
}
</style>
